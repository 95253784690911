import React from "react";
import "./UnderMaintenance.css";
import BPM_Missions from "../../assets/logo/bpm_missions.png";

const UnderMaintenance = () => {
  return (
    <div className="maintenance-container">
      <div className="maintenance-logo">
        <img src={BPM_Missions} alt="BPM Missions Logo" />
      </div>
      <div className="maintenance-content">
        <h1>CLOSED</h1>
        <p>BPM Missions are on hold while we make some changes.</p>
        <p>The missions will continue later in the year.</p>
        <p className="info-link">
          Visit&nbsp;
          <a href="https://www.missionbpm.com" target="_blank" rel="noreferrer">
            www.missionbpm.com
          </a>&nbsp;for more information.
        </p>
      </div>
    </div>
  );
};

export default UnderMaintenance;
