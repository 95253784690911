import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Navigation from "./components/Navigation/Navigation";
import CommonBack from "./components/CommonBackground/CommonBack";
import AuthNavBar from "./components/AuthNavBar/AuthNavBar";
import { useSelector, useDispatch } from "react-redux";
import { login, logout } from "./redux/reducers/AuthUser";
import Loader from "./components/Loader/Loader";
import { fetchUser } from "./redux/reducers/UserDetails";
import { UserAuth } from "./components/Authcontext";
import DesktopLoader from "./assets/desktop_loading.mp4";
import MobileLoader from "./assets/mobile_loading.mp4";
import UnderMaintenance from "./components/UnderMaintenance/UnderMaintenance";

const App = () => {
  // Toggle maintenance mode
  const underMaintenance = true; // set to false to restore the normal site

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const { pageLoading } = UserAuth();
  const dispatch = useDispatch();

  const [isVideoPlayed, setIsVideoPlayed] = useState(
    sessionStorage.getItem("videoPlayed") || false
  );
  const [videoSource, setVideoSource] = useState(DesktopLoader);

  // Detect device type to load appropriate video
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes("android") || userAgent.includes("iphone")) {
      setVideoSource(MobileLoader);
    } else {
      setVideoSource(DesktopLoader);
    }
  }, []);

  // Manage video play state in sessionStorage
  function handleVideoPlaying() {
    sessionStorage.setItem("videoPlaying", "true");
    window.dispatchEvent(new Event("storage"));
  }

  document.addEventListener("visibilitychange", handleVideoPlaying);

  const handleVideoEnd = () => {
    sessionStorage.setItem("videoPlaying", "false");
    sessionStorage.setItem("videoPlayed", "true");
    window.dispatchEvent(new Event("storage"));
    setIsVideoPlayed(true);
  };

  const handleGetStarted = () => {
    sessionStorage.setItem("videoPlayed", "true");
    sessionStorage.setItem("videoPlaying", "false");
    window.dispatchEvent(new Event("storage"));
    setIsVideoPlayed(true);
  };

  // Check for logged-in user in localStorage
  useEffect(() => {
    const userFromLocalStorage =
      localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));

    if (userFromLocalStorage) {
      dispatch(login());
      dispatch(fetchUser());
    } else {
      dispatch(logout());
    }
  }, [dispatch]);

  return (
    <BrowserRouter>
      {underMaintenance ? (
        // Under maintenance routes:
        // "/" will render UnderMaintenance, any other path will also show UnderMaintenance
        <Routes>
          <Route path="/" element={<UnderMaintenance />} />
          <Route path="*" element={<UnderMaintenance />} />
        </Routes>
      ) : (
        // Normal site flow if NOT under maintenance
        <>
          {!isAuthenticated && !isVideoPlayed ? (
            // Intro video overlay
            <div className="video-overlay">
              <video
                id="introVideo"
                loop={false}
                muted
                autoPlay
                playsInline
                preload="auto"
                onEnded={handleVideoEnd}
              >
                <source src={videoSource} type="video/mp4" />
              </video>
              <button
                className="customPrimaryButton get-started-button"
                onClick={handleGetStarted}
              >
                Get Started
              </button>
            </div>
          ) : pageLoading ? (
            // If user is logged in or video is played, but data is still loading
            <Loader />
          ) : (
            // Normal site content
            <>
              {isAuthenticated ? <AuthNavBar /> : <Navbar />}
              <CommonBack />
              <Navigation />
              <Footer />
            </>
          )}
        </>
      )}
    </BrowserRouter>
  );
};

export default App;
